import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "teide360EN" } }) {
      ...FragmentPageYaml
    }
  }
`

export default function Teide360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/uneNuitAuPiedDuTeide.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Winter weather conditions can be harsh in the Canary Islands. The only
          clear night we had during our stay in December 2017 took place while
          we were stopping off at the Parador Hotel which is located right in
          the heart of Teide National Park on the island of Tenerife.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          44 frames panoramic, Bushman Gobi panoramic head, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F2.2, ISO 3200. Individual
          exposures of 20 seconds.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
